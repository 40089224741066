(function($) {

$('#toggle').click(function() {
   $(this).toggleClass('active');
   $('#overlay').toggleClass('open');
  });
  
  
  WebFontConfig=({
	google: {
		families: ['Roboto:200,300,400,500,700,600&display=swap',]
	}
   
  });
  
  (function(){
    var wf = document.createElement("script");
    wf.src = ("https:" == document.location.protocol ? "https":"http") + 
             "://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js";
    wf.type="text/javascript";
    wf.async="true";
    var t = document.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(wf, t)
  })();

// Slider

(function() {
 
	// store the slider in a local variable
	var $window = $(window),
		flexslider = { vars:{} };
   
	// tiny helper function to add breakpoints
	function getGridSize() {
	  return (window.innerWidth < 680) ? 1 :
	 		 (window.innerWidth < 991) ? 2 :
			 (window.innerWidth < 1200) ? 4 : 4;
	}
   
	$window.load(function() {
		setTimeout(function(){
			$('.flexslider').flexslider({
				animation: "slide",
				animationLoop: true,
				itemWidth: 308,
				itemMargin: 26,
				controlNav: false,
				minItems: getGridSize(), 
				maxItems: getGridSize()
			  });
		}, 100);
	});

	// check grid size on resize event
	$window.resize(function() {
	  var gridSize = getGridSize();
   
	  flexslider.vars.minItems = gridSize;
	  flexslider.vars.maxItems = gridSize;
	});
  }());

  //Aos Animation

AOS.init({
  offset: 200,
  duration: 800,
  disable: 'mobile',
  easing: 'ease-in-quad',
  delay: 100,
});


// Search Toggle
$('.search-toggle').addClass('closed');

$('.search-toggle .search-icon').click(function(e) {
  if ($('.search-toggle').hasClass('closed')) {
    $('.search-toggle').removeClass('closed').addClass('opened');
    $('.search-toggle, .search-container').addClass('opened');
    $('#search-terms').focus();
  } else {
    $('.search-toggle').removeClass('opened').addClass('closed');
    $('.search-toggle, .search-container').removeClass('opened');
  }
});

// Hamburger Toggle

$(document).ready(function () {
	$('.second-button').on('click', function () {
  
	  $('.animated-icon2').toggleClass('open');
	});
	
  });

  // Modal image 

  $(document).ready(function() {
	// executes when HTML-Document is loaded and DOM is ready
   console.log("document is ready");
	 
   $( '.lightbox' ).each(function( index ) {
	 $(this).click(function(){
		   var imgSrc = $(this).children("img").attr("data-img");
		 console.log(imgSrc);
		 $('body').append( '<div id="lightbox-modal" class="modal fade" tabindex="-1" role="dialog"><div class="modal-dialog modal-lg modal-dialog-centered"><div class="modal-content modal-about-content"><div class="modal-header modal-about-header"><button type="button" class="close close-about" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body"><img src="' + imgSrc + '" class="img-fluid"></div></div></div></div>' );
		   $('#lightbox-modal').modal({show:true});
   
		 $('#lightbox-modal').on('hidden.bs.modal', function (e) {
		   $(this).remove();
		 })
		 return false;
	 });
   });
   });

// Accordion

$("#accordion").on("hide.bs.collapse show.bs.collapse", e => {
	$(e.target)
	  .prev()
	  .find("i:last-child")
	  .toggleClass("fa-minus fa-plus");
  });


// lightbox gallery
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
	event.preventDefault();
	$(this).ekkoLightbox({
		alwaysShowClose: true
	});
});

// Contact Form Get Title

$(document).ready(function(){
	var title = $('h1').attr('datatitle');
	$("input#title_product").val(title);
	});

// Anchor Link smooth scroll

$('.js-anchor-link').click(function(e){
	e.preventDefault();
	var target = $($(this).attr('href'));
	if(target.length){
	  var scrollTo = target.offset().top;
	  $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
	}
  });

// Prevent Image to save 

$("body").on("contextmenu", "img", function(e) {
	return false;
  });

// Search Distributor
/* USA Search */
$('[data-search]').on('keyup', function() {
	var searchVal = $(this).val();
	var filterItems = $('[data-filter-item]');

	if ( searchVal != '' ) {
		filterItems.addClass('hidden');
		$('[data-filter-item][data-filter-name*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
	} else {
		filterItems.removeClass('hidden');
	}
});

/* Africa Search */
$('[data-search-africa]').on('keyup', function() {
	var searchVal = $(this).val();
	var filterItems = $('[data-filter-item-af]');

	if ( searchVal != '' ) {
		filterItems.addClass('hidden');
		$('[data-filter-item-af][data-filter-name-af*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
	} else {
		filterItems.removeClass('hidden');
	}
});
/* Asia Search */
$('[data-search-asia]').on('keyup', function() {
	var searchVal = $(this).val();
	var filterItems = $('[data-filter-item-asia]');

	if ( searchVal != '' ) {
		filterItems.addClass('hidden');
		$('[data-filter-item-asia][data-filter-name-asia*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
	} else {
		filterItems.removeClass('hidden');
	}
});
/* Europe Search */
$('[data-search-europe]').on('keyup', function() {
	var searchVal = $(this).val();
	var filterItems = $('[data-filter-item-eur]');

	if ( searchVal != '' ) {
		filterItems.addClass('hidden');
		$('[data-filter-item-eur][data-filter-name-eur*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
	} else {
		filterItems.removeClass('hidden');
	}
});
/* Americas Search */
$('[data-search-americas]').on('keyup', function() {
	var searchVal = $(this).val();
	var filterItems = $('[data-filter-item-ame]');

	if ( searchVal != '' ) {
		filterItems.addClass('hidden');
		$('[data-filter-item-ame][data-filter-name-ame*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
	} else {
		filterItems.removeClass('hidden');
	}
});

})(jQuery);
