template_directory = site.theme_path;

(function($) {
// Navbar fixed

///////////////// fixed menu on scroll for desktop
//if ($(window).width() > 992) {
    $(window).scroll(function(){  
       if ($(this).scrollTop() > 60) {
          $('#navbar_top').addClass("fixed-top-zero");
          $('#navbar_top').removeClass("fixed-top-navbar-mobile");
          $('#navbar_top').addClass("top-banner-mobile");
          $('.fa-1x').addClass("fa-1x-dark");
          
          $('.navbar-brand-img img').attr('src',template_directory+'/assets/img/logo.png');
          // add padding top to show content behind navbar
         // $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
        }else{
          $('#navbar_top').removeClass("fixed-top-zero");
          $('#navbar_top').addClass("fixed-top-navbar-mobile");
          $('#navbar_top').removeClass("top-banner-mobile");
          $('.fa-1x').removeClass("fa-1x-dark");
          $('.navbar-brand-img img').attr('src',template_directory+'/assets/img/white-logo.png');
           // remove padding top from body
          //$('body').css('padding-top', '0');
        }   
    });
 // } // end if

   /*  Back to top */
   $(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#scroll').fadeIn(); 
        } else { 
            $('#scroll').fadeOut(); 
        } 
    }); 
    $('#scroll').click(function(){ 
        $("html, body").animate({ scrollTop: 0 }, 600); 
        return false; 
    }); 
});

})(jQuery);

function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }
  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  document.addEventListener('mousedown',function(event){
    var side_dom    = document.getElementById("mySidenav")
    if(side_dom.style.width == '250px'){
            if(!side_dom.contains(event.target)){
                    document.getElementById("mySidenav").style.width = "0";
            }
    }       
})

